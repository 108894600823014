import Alpine from 'alpinejs'
import Slider from './js/slider'
import contactForm from './js/form'


window.Alpine = Alpine
Alpine.start()

Slider();
contactForm.init();

// Function to check if a color is light
function isLightColor(color) {
  let r, g, b;

  if (color.startsWith('rgb')) {
    const match = color.match(/(\d+),\s*(\d+),\s*(\d+)/);
    if (match) {
      [r, g, b] = match.slice(1).map(Number);
    }
  } else if (color.startsWith('#')) {
    color = color.replace(/^#/, '');
    if (color.length === 3) {
      color = color.split('').map(c => c + c).join('');
    }
    r = parseInt(color.substr(0, 2), 16);
    g = parseInt(color.substr(2, 2), 16);
    b = parseInt(color.substr(4, 2), 16);
  }

  if (r === undefined || g === undefined || b === undefined) {
    return false;
  }

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.7;
}

// Function to update CSS variables
function updateNavStyles() {
  const sections = document.querySelectorAll('.js-section');
  const root = document.documentElement;
  let intersectingSection = null;

  sections.forEach(section => {
    const rect = section.getBoundingClientRect();
    if (rect.top <= 0 && rect.bottom > 0) {
      intersectingSection = section;
    }
  });

  if (intersectingSection) {
    const isHeroSection = intersectingSection.classList.contains('js-section--hero');
    const isWithinFirst100px = window.scrollY <= 100;

    if (isHeroSection && isWithinFirst100px) {
      root.style.setProperty('--nav-logo-color', '#ffffff');
      root.style.setProperty('--nav-bg-color', 'transparent');
      root.style.setProperty('--nav-text-color', '#ffffff');
      root.style.setProperty('--nav-blur', '0px');
      root.style.setProperty('--nav-popover-bg-color', 'rgba(255, 255, 255, 0.15)');
      root.style.setProperty('--nav-popover-text-color', '#ffffff');
    } else {
      const bgColor = window.getComputedStyle(intersectingSection).backgroundColor;
      if (isLightColor(bgColor)) {
        root.style.setProperty('--nav-logo-color', '#E0631C');
        root.style.setProperty('--nav-bg-color', '#fef7f1');
        root.style.setProperty('--nav-text-color', '#1A333D');
        root.style.setProperty('--nav-blur', '0px');
        root.style.setProperty('--nav-popover-bg-color', '#FEF7F1');
        root.style.setProperty('--nav-popover-text-color', '#4D5C63');

      } else {
        root.style.setProperty('--nav-logo-color', '#ffffff');
        root.style.setProperty('--nav-bg-color', 'rgba(255, 255, 255, 0.15)');
        root.style.setProperty('--nav-text-color', '#fff');
        root.style.setProperty('--nav-blur', '30px');
        root.style.setProperty('--nav-popover-bg-color', 'rgba(255, 255, 255, 0.15)');
        root.style.setProperty('--nav-popover-text-color', '#ffffff');
      }
    }
  }
}

// Add scroll event listener
window.addEventListener('scroll', updateNavStyles);

// Initial call to set styles
updateNavStyles();