const contactForm = {
    elements: {
        container: '.js-contact-container',
        form: '.js-contact-form',
        button: '.js-contact-form-send',
        fieldset: '.js-contact-form-fieldset',
        message: '.js-contact-form-message',
    },
    strings: {
        submitUrl: contactFormGlobals.submitUrl,
    },
    init: function() {
        const self = this;
        const form = document.querySelector(self.elements.form);
        const button = document.querySelector(self.elements.button);

        if (form) {
            form.addEventListener('submit', self.handleSubmit.bind(self));
        }

        if (button) {
            button.addEventListener('click', self.validateForm.bind(self));
        }

        document.querySelectorAll('*[required]').forEach(function(el) {
            el.addEventListener('change', self.handleFieldChange.bind(self));
        });
    },
    validateForm: function(e) {
        const form = document.querySelector(this.elements.form);
        const invalidFields = form.querySelectorAll('*[required]');

        invalidFields.forEach(field => {
            if (!field.value || field.value === '') {
                field.closest('.grid').classList.add('is-invalid');
            } else {
                field.closest('.grid').classList.remove('is-invalid');
            }
        });

        if (form.querySelectorAll('.is-invalid').length > 0) {
            const firstInvalid = form.querySelector('.is-invalid');
            const offset = firstInvalid.getBoundingClientRect().top + window.pageYOffset - 100;
            window.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        }
    },
    handleFieldChange: function(e) {
        if (e.target.value !== '') {
            e.target.closest('.grid').classList.remove('is-invalid');
        }
    },
    handleSubmit: function(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const fieldset = form.querySelector(this.elements.fieldset);
        const message = form.querySelector(this.elements.message);

        fieldset.disabled = true;
        form.classList.add('is-loading');

        // Append form data based on the Twig template structure
        formData.append('message[Name]', form.querySelector('[name="name"]').value.trim());
        formData.append('message[Email]', form.querySelector('[name="email"]').value.trim());
        formData.append('message[Phone]', form.querySelector('[name="phone"]').value.trim());
        formData.append('message[Message]', form.querySelector('[name="text"]').value.trim());
        formData.append('message[Read and accept the Privacy Policy]', form.querySelector('[name="accept-privacy"]').checked ? 'Yes' : 'No');

        formData.append('fromName', form.querySelector('[name="name"]').value.trim());
        formData.append('fromEmail', form.querySelector('[name="email"]').value.trim());

        formData.append('action', 'contact-form/send');

        fetch(this.strings.submitUrl, {
            method: 'POST',
            body: formData
        })
        .then(data => {
            message.textContent = contactFormGlobals.contact.messageSuccess;
            message.classList.add('text-green');
            form.reset();
        })
        .catch(error => {
            message.textContent = contactFormGlobals.contact.messageError;
            message.classList.add('text-red');
            console.log(error)
        })
        .finally(() => {
            fieldset.disabled = false;
            form.classList.remove('is-loading');
        });
    },
    restart: function() {
        const self = this;
        const form = document.querySelector(self.elements.form);
        const button = document.querySelector(self.elements.button);

        if (form) {
            form.removeEventListener('submit', self.handleSubmit);
        }
        if (button) {
            button.removeEventListener('click', self.validateForm);
        }
        document.querySelectorAll('*[required]').forEach(el => {
            el.removeEventListener('change', self.handleFieldChange);
        });

        self.init();
    },
};

export default contactForm;