import Swiper from 'swiper';
import 'swiper/css';
import { FreeMode, Autoplay, Navigation, Pagination } from 'swiper/modules';

const swiper = () => {
    let swiperInstances = [];
    const swipers = document.querySelectorAll('.swiper');

    swipers.forEach(swiper => {
        const slug = swiper.dataset.swiperSlug;

        let options = {};
        switch (slug) {
            case 'hero':
                options = {
                    modules: [Navigation],
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    autoplay: null,
                };
                break;

            case 'logo-group':
                options = {
                    modules: [FreeMode, Autoplay],
                    loop: true,
                    freeMode: {
                        enabled: true,
                        momentum: true,
                    },
                    autoplay: {
                      delay: 1,
                      disableOnInteraction: false
                    },
                    slidesPerView: '3',
                    spaceBetween: 60,
                    speed: 4000,
                };
                break;
        }

        swiperInstances.push(new Swiper(swiper, options));
    });
}

export default swiper;